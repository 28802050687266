import * as L from 'leaflet'
import * as T from '@turf/turf'
import { Pin } from './pin'
import { Utils } from './utils'

export class PinView {
  pin: Pin
  
  private map: L.Map
  private _showOverlay: boolean
  private _showLgaOverlay: boolean
  private _icon: L.Icon
  private _warningIcon: L.Icon 
  private _lgasOfConcern: string[]

  overlay: L.Polygon
  lgaOverlay?: L.GeoJSON
  marker: L.Marker
  pane?: string
  
  private dragging: boolean = false

  constructor(pin: Pin, 
              map: L.Map, 
              showOverlay: boolean, 
              showLgaOverlay: boolean, 
              onDrag: (latLng: L.LatLng, pinView: PinView) => void, 
              onDragEnd: (latLng: L.LatLng, pinView: PinView) => void,
              onMouseOver: (isOver: boolean, pinView: PinView) => void,
              lgasOfConcern: string[],
              pane?: string) 
  {
    this.map = map
    this.pin = pin
    this.pane = pane
    this._lgasOfConcern = lgasOfConcern
    this._showOverlay = showOverlay
    this._showLgaOverlay = showLgaOverlay
    this.overlay = PinView.createOverlay(pin, showOverlay, showLgaOverlay && !this.isLgaOfConcern(), pane).addTo(map)
    this._icon = PinView.createIcon(pin)
    this._warningIcon = PinView.createWarningIcon(pin)
    this.marker = PinView.createMarker(pin, this.isLgaOfConcern() ? this._warningIcon : this._icon)
      .on("drag", () => {
        onDrag(this.marker.getLatLng(), this)
      })
      .on("dragstart", () => {
        this.dragging = true
      })
      .on("dragend", () => {
        this.dragging = false
        this.updateIcon()
        onDragEnd(this.marker.getLatLng(), this)
      })
      .on("mouseover", () => {
        if (this.dragging) { return }
        onMouseOver(true, this)
      })
      .on("mouseout", () => {
        if (this.dragging) { return }
        onMouseOver(false, this)
      }).addTo(map)
    this.lgaOverlay = PinView.createLGAOverlay(pin, pane)?.addTo(map)
  }

  get showOverlay() {
    return this._showOverlay
  }

  set showOverlay(newShowOverlay: boolean) { 
    this._showOverlay = newShowOverlay
    this.updatePin()
  }

  get showLgaOverlay() {
    return this._showLgaOverlay
  }

  set showLgaOverlay(newShowLgaOverlay: boolean) {
    this._showLgaOverlay = newShowLgaOverlay
    this.updatePin()
  }
  
  updateIcon() {
    let newIcon = this.isLgaOfConcern() ? this._warningIcon : this._icon
    if (this.marker.getIcon() != newIcon) {
      this.marker.setIcon(newIcon)
    }
  }

  updateLocation(location?: T.Feature<T.Point>) {
    if (location) {
      this.pin.location = location
    }
    this.updatePin()
  }

  private setStyle() { 
    this.overlay.setStyle(PinView.getStyle(this.showOverlay, this.pin))
  }
  
  private static getStyle(showOverlay: boolean, pin: Pin): L.PathOptions {
    if (showOverlay) {
      return {
        weight: 3,
        color: pin.color.getColorString(),
        opacity: 0.8,
        fillColor: pin.color.getColorString(),
        fillOpacity: 0.05,
      }
    } else {
      return {
        weight: 3,
        color: pin.color.getColorString(),
        opacity: 0.4,
        fillColor: pin.color.getColorString(),
        fillOpacity: 0.025,
      }
    }
  }

  isLgaOfConcern(): boolean {
    if (this.pin.lga?.properties) {
      let lga = this.pin.lga.properties['nsw_lga__3']
      if (lga) {
        return this._lgasOfConcern.includes(lga)
      }
    }
    return false
  }

  private updatePin() {
    this.marker.setLatLng(Utils.pointToLatLng(this.pin.location))

    let includeLGA = this.showLgaOverlay && !this.isLgaOfConcern()

    this.setStyle()
    let newPoints = this.pin.getFeature(includeLGA).geometry.coordinates[0].map(p => { return Utils.coordsToLatLng(p) })
    this.overlay.setLatLngs(newPoints)
    // this.overlay = PinView.createOverlay(this.pin, this.showLgaOverlay, this.pane)

    this.lgaOverlay?.remove()
    this.lgaOverlay = PinView.createLGAOverlay(this.pin, this.pane)

    this.show()
  }

  remove() {
    this.marker.remove()
    this.overlay.remove()
    this.lgaOverlay?.remove()
  }

  show() {
    if (this.showOverlay) {
      this.overlay.addTo(this.map)
      if (this.showLgaOverlay) {
        this.lgaOverlay?.addTo(this.map)
      } else {
        this.lgaOverlay?.remove()
      }
    }
  }

  private static createOverlay(pin: Pin, showOverlay: boolean, shouldIncludeLGA: boolean, pane?: string): L.Polygon {
    let points = pin.getFeature(shouldIncludeLGA).geometry.coordinates[0].map(p => { return Utils.coordsToLatLng(p) })
    return L.polygon(points, {
      pane: pane,
    }).setStyle(this.getStyle(showOverlay, pin))
  }

  private static createMarker(pin: Pin, icon: L.Icon): L.Marker {
    return L.marker(Utils.pointToLatLng(pin.location), {
      draggable: true,
      autoPan: true,
      interactive: true,
      icon: icon,
    }) 
  }

  static createIcon(pin: Pin): L.Icon {
    return L.icon({
      iconUrl: pin.color.getIconUrl(false).toString(),
      iconRetinaUrl: pin.color.getRetinaIconUrl(false).toString(),
      iconSize: [48, 48],
      iconAnchor: [24, 48],
      popupAnchor: [0, -32],
    })
  }

  static createWarningIcon(pin: Pin): L.Icon {
    return L.icon({
      iconUrl: pin.color.getIconUrl(true).toString(),
      iconRetinaUrl: pin.color.getRetinaIconUrl(true).toString(),
      iconSize: [48, 48],
      iconAnchor: [24, 48],
      popupAnchor: [0, -32],
    })
  }

  private static createLGAOverlay(pin: Pin, pane?: string): L.GeoJSON | null {
    if (pin.lga) {
      return L.geoJSON(pin.lga, {
        style: () => {
          return {
            weight: 2,
            color: pin.color.getColorString(),
            opacity: 0.4,
            fill: false,
            pane: pane,
          }
        }
      })
    } else {
      return null
    }
  }
}
