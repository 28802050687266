import * as T from "@turf/turf"
import * as L from "leaflet"

import "maplibre-gl"
import "@maplibre/maplibre-gl-leaflet"
import "@fortawesome/fontawesome-free"
import * as elements from '../node_modules/typed-html/dist/esm/src/elements'
import { UAParser } from "ua-parser-js"
import { compare } from "compare-versions"

import { Pin, PinColor } from "./pin"
import { PinView } from "./pinView"
import { Utils } from "./utils"
import { FriendZone } from "./friendzone"
import { FriendZoneView } from "./friendZoneView"

enum Radius {
  Five = 5,
  Ten = 10,
  Fifteen = 15,
}

let map: L.Map

let userPane: HTMLElement

let friendZone: FriendZone | null
let friendZoneView: FriendZoneView | null
let friendZonePane: HTMLElement

let pinViews: Array<PinView> = []

let lgaFeatureCollection: T.FeatureCollection<T.Polygon>

let lastId = 0
let ids: string[] = ['id-0']

let lgasOfConcern: string[] = [
]

// Area radius in km
let radius: Radius = Radius.Five

function createId(): string {
  lastId += 1
  return "id-" + lastId
}

function isLGAEnabled() {
  return true
}

function isFirstPin(index: number): boolean {
  return (index == 0)
}

function updateRadius(newRadius: Radius) {
  radius = newRadius
  updateAllPins(false, isLGAEnabled(), newRadius)
}

function addPin(point?: T.Feature<T.Point>, shouldUpdateText?: boolean, shouldUpdateBounds?: boolean) {
  let index = pinViews.length
  let pinPoint = point

  if (!pinPoint) {
    let bearing = Math.random() * 360 - 180
    pinPoint = T.destination(pinViews[0].pin.location, 3, bearing, {})
  }
  
  let newPin = new Pin(pinPoint, colorIndexMap[index], radius, getLGA)

  let id: string
  if (!isFirstPin(index)) {
    id = createId()
    ids.push(id)
  } else {
    id = "id-0"
  }
  

  let onDrag = (latLng: L.LatLng, pinView: PinView) => {
    pinView.updateLocation(Utils.toPoint(latLng))
    getAddressInput(id).value = "" + T.round(pinView.pin.latLng.lat, 4) + ", " + T.round(pinView.pin.latLng.lng, 4)
    updateFriendZone()
  }

  let onDragEnd = (latLng: L.LatLng, pinView: PinView) => {
    pushState()
    updatePinImage(id)
  }

  let onMouseOver = (isOver: boolean, pinView: PinView) => {
    pinView.showOverlay = isOver || index == 0
  }

  let newPinView = new PinView(newPin, map, isFirstPin(index), isLGAEnabled(), onDrag, onDragEnd, onMouseOver, lgasOfConcern)

  if (index == 4) {
    getAddAddressButton().classList.add('hidden')
  }

  pinViews.push(newPinView)

  if (!isFirstPin(index)) {
    addAddressField(index, id)
  }

  if (shouldUpdateText) {
    getAddressInput(id).value = "" + T.round(newPinView.pin.latLng.lat, 4) + ", " + T.round(newPinView.pin.latLng.lng, 4)
  }

  newPinView.show()
  updateFriendZone()
  pushState()

  if (shouldUpdateBounds) {
    fitMapToBounds()
  }
}

function removePin(index: number) {
  console.log("removing pin: ", index)
  console.log("pin count: ", pinViews.length)

  if (index == 0) {
    console.log("Cannot remove first pin, aborting")
    return
  }

  pinViews[index].remove()
  getAddressField(ids[index]).remove()

  pinViews.splice(index, 1)
  ids.splice(index, 1)

  if (pinViews.length < 5) {
    getAddAddressButton().classList.remove('hidden')
  }

  updateFriendZone()
}

function updatePin(index: number, shouldUpdateText: boolean, point?: T.Feature<T.Point>) {  
  if (!pinViews.hasOwnProperty(index)) {
    console.log("Attempting to update non-existent pin, aborting")
    return
  }
  
  let pinView = pinViews[index]
  pinView.updateLocation(point)

  if (shouldUpdateText) {
    getAddressInput(ids[index]).value = "" + T.round(pinView.pin.latLng.lat, 4) + ", " + T.round(pinView.pin.latLng.lng, 4)
  }

  updateFriendZone()
  pushState()
}

function updateAllPins(shouldUpdateText: boolean, includeLga: boolean, radius: Radius) {
  pinViews.forEach((pinView, index) => {
    pinView.pin.radius = radius
    pinView.showLgaOverlay = includeLga
    updatePin(index, shouldUpdateText)
  });
}

function serialisePins(pins: Array<Pin>): string {
  let string = ""
  pins.forEach(pin => {
    string +=  T.round(pin.location.geometry.coordinates[0], 4) 
    string += ","
    string += T.round(pin.location.geometry.coordinates[1], 4)
    string += ";"
  });
  return string
}

function deserialisePoints(string: string): Array<T.Feature<T.Point>> {
  let pinStrings = string.split(';').filter(s => { return s.length != 0 })
  return pinStrings.map((ps, index) => {
    let coords = ps.split(',').map(c => { return Number(c).valueOf() })
    return T.point(coords)
  })
}

const colorIndexMap = [
  PinColor.Red,
  PinColor.Blue,
  PinColor.Blue,
  PinColor.Blue,
  PinColor.Blue,
]

function pushState() {
  let pins = pinViews.map(v => { return v.pin })
  const serialisableState = {
    pins: serialisePins(pins),
    radius: radius,
  }

  const newURL = new URL(window.location.href)
  newURL.searchParams.set('pins', serialisePins(pins))
  newURL.searchParams.set('radius', String(radius).valueOf())
  history.pushState(serialisableState, document.title, newURL)
}


async function initMap() {
  const url = new URL(window.location.href)

  map = L.map('map', {
    wheelPxPerZoomLevel: 120,
    renderer: L.svg({ padding: 0.5 }),
  })

  let parser = new UAParser()
  let ua = parser.getResult()

  if (compare(ua.os.version, '15.0.0', '>=') && ua.browser.name == 'Mobile Safari') {
    // Vector tiles bug out on Safari 15+, use 2x rasters instead
    L.tileLayer('https://api.maptiler.com/maps/bright/{z}/{x}/{y}@2x.png?key=llESfJYmDW9W4SQldD9g',{
      tileSize: 512,
      zoomOffset: -1,
      minZoom: 1,
      attribution: "\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e",
      crossOrigin: true
    }).addTo(map)
  } else {
    // MapLibreGL-Leaflet doesn't have typescript bindings
    // TODO: create and publish typescript bindings
    // @ts-ignore
    L.maplibreGL({
      style: 'https://api.maptiler.com/maps/bright/style.json?key=llESfJYmDW9W4SQldD9g',
      // @ts-ignore
      attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
    }).addTo(map)
  }

  userPane = map.createPane("userPane")
  userPane.style.zIndex = '410'

  let defaultPoint = Utils.toPoint(L.latLng({lat: -33.888889295001356, lng: 151.2188970157877}))
  
  map.setView(Utils.pointToLatLng(defaultPoint), 13);

  friendZonePane = map.createPane("friendZonePane")
  friendZonePane.style.zIndex = '420'

  await loadFeatures()

  if (url.searchParams.has('radius')) {
    let radiusString = url.searchParams.get('radius')
    if (radiusString == '5') {
      setActiveRadiusTab(Radius.Five)
    } else if (radiusString == '10') {
      setActiveRadiusTab(Radius.Ten)
    } else if (radiusString == '15') {
      setActiveRadiusTab(Radius.Fifteen)
    } else {
      setActiveRadiusTab(Radius.Five)
    }
  } else {
    setActiveRadiusTab(Radius.Five)
  }

  const pointString = url.searchParams.get('pins')
  if (pointString) {
    let pins = deserialisePoints(pointString)
    pins.forEach(point => {
      addPin(point, true)
    });
  } else {
    addPin(defaultPoint)
  }
  fitMapToBounds()
}

function getPosition(options): Promise<GeolocationPosition> {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(resolve, reject, options)
    } else {
      // Browser doesn't support Geolocation
      reject()
    }
  })
}

function updateFriendZone() {
  if (!friendZone) {
    friendZone = new FriendZone()
  }

  friendZone.pins = pinViews.map(p => { return p.pin })

  if (!friendZoneView) {
    friendZoneView = new FriendZoneView(friendZone, isLGAEnabled(), lgasOfConcern, "friendZonePane")
    friendZoneView.showOn(map)
  } else {
    friendZoneView.updateFriendZone(friendZone, map, isLGAEnabled())
  }

  if (friendZoneView.isAnyLGAOfConcern()) {
    getLgaOfConcernInfo().classList.remove('hidden')  
  } else {
    getLgaOfConcernInfo().classList.add('hidden')
  }

  let feature = friendZone.getFeature(isLGAEnabled() && !friendZoneView.isAnyLGAOfConcern())

  const friendshipDeniedBanner = document.getElementById("friendship-denied-banner");
  if (!feature && pinViews.length > 1) {
    friendshipDeniedBanner.classList.remove('hide')
    friendshipDeniedBanner.classList.add('show')
  } else {
    if (friendshipDeniedBanner.classList.contains('show')) {
      friendshipDeniedBanner.classList.remove('show')
      friendshipDeniedBanner.classList.add('hide')
      setTimeout(function() {
        friendshipDeniedBanner.classList.remove("hide");
      }, 500);
    }
  }
}

function fitMapToBounds() {
  let bounds = pinViews[0].overlay.getBounds()
  pinViews.slice(1).forEach(pinView => {
    bounds.extend(pinView.overlay.getBounds())
  });
  map.flyToBounds(bounds)
}

// Returns LGA features as geojson
async function loadFeatures() {
  if (!lgaFeatureCollection) {
    let response = await fetch('https://api.maptiler.com/data/8c603962-768d-4207-bbd6-9a1f22402699/features.json?key=llESfJYmDW9W4SQldD9g')
    let json = await response.json()
    lgaFeatureCollection = json
    return json
  } else {
    return lgaFeatureCollection
  }
}

function getLGA(point: T.Feature<T.Point>): T.Feature<T.Polygon> | null {
  if (!lgaFeatureCollection) { return undefined }

  let feature = T.featureReduce(lgaFeatureCollection, (previous, current, index) => {
    if (T.booleanPointInPolygon(point, current)) {
      return current
    } else {
      return previous
    }
  }, null)

  if (feature && feature.properties["nsw_lga__2"] != "UNINCORPORATED") {
    return feature
  } else {
    return null
  }
}

// HTML Interaction

function addAddressField(index: number, id: string) {
  let fragment = createFragment(
    <div class="field has-addons" id={"address-field-"+id}>
      <div class="pin">
        <img id={"pin-image-"+id} src={colorIndexMap[index].getRetinaIconUrl(pinViews[index].isLgaOfConcern()).toString()} width="32" height="32"></img>
      </div>
      <div class="control is-expanded">
        <input type="text" id={"address-input-"+id} class="input" placeholder="Enter an address"></input>
      </div>
      <div class="control">
        <div class="button addon-button is-link" id={"address-search-button-"+id}>
          <i class="fas fa-search" id={"address-search-button-icon-"+id}></i>
        </div>
      </div>
      <div class="control">
        <div class="button addon-button" id={"remove-address-button-"+id}>
          <i class="fas fa-times"></i>
        </div>
      </div>
    </div>
  )

  getAddressFieldContainer().appendChild(fragment)
  getAddressButton(id).onclick = () => { searchButtonClicked(id) }
  getRemoveAddressButton(id).onclick = () => { removeAddressButtonClicked(id) }
}

function updatePinImage(id) {
  let pinImage = document.getElementById("pin-image-"+id) as HTMLImageElement
  let index = ids.indexOf(id)
  pinImage.src = colorIndexMap[index].getRetinaIconUrl(pinViews[index].isLgaOfConcern()).toString()
}

function share() {
  if (navigator.share) {
    navigator.share({
      url: window.location.toString()
    })
    console.log("Shared successfully")
  } else {
    navigator.clipboard.writeText(window.location.toString())
    getCopyLinkInfo().classList.remove("hidden")
    setTimeout(() => {
      getCopyLinkInfo().classList.add("hidden")
    }, 3000)
  }
}

function createFragment(string: string): DocumentFragment {
  return document.createRange().createContextualFragment(string)
}

function getCopyLinkInfo(): HTMLElement {
  return document.getElementById("copied-link-info")
}

function getAddressField(id: string): HTMLInputElement | null {
  return document.getElementById("address-field-"+id) as HTMLInputElement
}

function getAddressInput(id: string): HTMLInputElement | null {
  return document.getElementById("address-input-"+id) as HTMLInputElement
}

function getAddressSearchButton(id: string): HTMLElement | null {
  return document.getElementById("address-search-button-"+id)
}

function getAddressSearchButtonIcon(id: string): HTMLElement | null {
  return document.getElementById("address-search-button-icon-"+id)
}

function getAddressFieldContainer(): HTMLElement {
  return document.getElementById("address-field-container")
}

function getAddressButton(id: string): HTMLElement {
  return document.getElementById("address-search-button-"+id)
}

function getRemoveAddressButton(id: string): HTMLElement {
  return document.getElementById("remove-address-button-"+id)
}

function getAddAddressButton(): HTMLElement {
  return document.getElementById('add-address-button')
}

function getShareButton(): HTMLElement {
  return document.getElementById('share-button')
}

function getLgaOfConcernInfo(): HTMLElement {
  return document.getElementById('lga-of-concern-info')
}

getRadiusTab(Radius.Five).onclick = () => { setActiveRadiusTab(Radius.Five) }
getRadiusTab(Radius.Ten).onclick = () => { setActiveRadiusTab(Radius.Ten) }
getRadiusTab(Radius.Fifteen).onclick = () => { setActiveRadiusTab(Radius.Fifteen) }

function setActiveRadiusTab(r: Radius) {
  // Clear current selection
  getRadiusTab(Radius.Five).classList.remove("is-active")
  getRadiusTab(Radius.Ten).classList.remove("is-active")
  getRadiusTab(Radius.Fifteen).classList.remove("is-active")

  updateRadius(r)

  // Select the new tab
  getRadiusTab(r)?.classList.add("is-active")
}

function getRadiusTab(r: Radius): HTMLElement | undefined {
  switch (r) {
    case Radius.Five: return document.getElementById("5km-radius-tab")
    case Radius.Ten: return document.getElementById("10km-radius-tab")
    case Radius.Fifteen: return document.getElementById("15km-radius-tab")
  }
}

document.addEventListener('DOMContentLoaded', function() {
  loadFeatures().then(() => {
    initMap()
    getAddAddressButton().onclick = () => { addPin(null, false, true) }
    getShareButton().onclick = () => { share() }
  })
}, false)

document.getElementById('user-pin-button').onclick = () => { pinButtonClicked() }
async function pinButtonClicked() {
  let posOptions = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 60000
  };

  setLocationIconLoadingState(true)
  const position = await getPosition(posOptions)
  setLocationIconLoadingState(false)
  updatePin(0, true, T.point([position.coords.longitude, position.coords.latitude]))
  fitMapToBounds()
}

document.getElementById('address-search-button-id-0').onclick = () => { searchButtonClicked("id-0") }
async function searchButtonClicked(id) {
  const addressInput = getAddressInput(id) as HTMLInputElement
  if (!!addressInput.value) {
    let icon = getAddressSearchButtonIcon(id)
    setSearchIconLoadingState(true, icon)


    let query = addressInput.value
    let response = await fetch('https://api.positionstack.com/v1/forward?access_key=d61146484c74610ae71422f592c940fb&country=AU&limit=1&query='+query)
    let data = await response.json()

    if (data.data && data.data.length > 0) {
      const result = data.data[0]
      let pos = L.latLng(result.latitude, result.longitude)
      let label = result.label
      addressInput.value = label
      setSearchIconLoadingState(false, icon)
      updatePin(ids.indexOf(id), false, Utils.toPoint(pos))
      fitMapToBounds()
    } else { 
      // TODO: show error state
      setSearchIconLoadingState(false, icon)
      return
    }
  }
}

function setSearchIconLoadingState(isLoading: boolean, icon: HTMLElement) {
  if (isLoading) {
    icon.classList.remove('fa-search')
    icon.classList.add('fa-spinner')
    icon.classList.add('fa-spin')
  } else {
    icon.classList.remove('fa-spin')
    icon.classList.remove('fa-spinner')
    icon.classList.add('fa-search')
  }
}

function setLocationIconLoadingState(isLoading: boolean) {
  let icon = document.getElementById('user-pin-button-icon')
  if (isLoading) {
    icon.classList.remove('fa-location-arrow')
    icon.classList.add('fa-spinner')
    icon.classList.add('fa-spin')
  } else {
    icon.classList.remove('fa-spin')
    icon.classList.remove('fa-spinner')
    icon.classList.add('fa-location-arrow')
  }
}

function removeAddressButtonClicked(id) {
  let index = ids.indexOf(id)
  removePin(index)
}

document.getElementById('close-warning-button').onclick = () => { closeWarningButtonClicked() }
function closeWarningButtonClicked() {
  const warningMessage = document.getElementById("warning-message");
  warningMessage.remove()
}
