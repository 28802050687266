import * as L from 'leaflet'
import { Pin } from './pin'
import { Utils } from './utils'
import { FriendZone } from './friendzone'
export class FriendZoneView {
  overlay?: L.GeoJSON
  pane?: string
  friendZone: FriendZone

  private _lgasOfConcern: string[]

  constructor(friendZone: FriendZone, shouldShowLGA: boolean, lgasOfConcern: string[], pane?: string) {
    this.friendZone = friendZone
    this.pane = pane
    this._lgasOfConcern = lgasOfConcern
    this.overlay = this.createOverlay(shouldShowLGA, pane)
  }

  updateFriendZone(friendZone: FriendZone, map: L.Map, shouldShowLGA: boolean) {
    this.friendZone = friendZone
    this.overlay?.remove()
    this.overlay = this.createOverlay(shouldShowLGA, this.pane)
    this.showOn(map)
  }

  hide() {
    this.overlay?.remove()
  }

  showOn(map: L.Map) {
    this.overlay?.addTo(map)
  }

  isAnyLGAOfConcern(): boolean {
    return this.friendZone.pins
      .map(p => { 
        return p.lga?.properties['nsw_lga__3'] 
      })
      .filter(lga => { 
        if (lga) {
          return this._lgasOfConcern.includes(lga)
        } else {
          return false
        }
      })
      .length > 0
  }

  createOverlay(shouldIncludeLGA: boolean, pane?: string): L.GeoJSON | null {
    let feature = this.friendZone.getFeature(shouldIncludeLGA && !this.isAnyLGAOfConcern())
    if (!feature) { 
      return null 
    }
    
    return L.geoJSON(feature, {
      style: () => {
        return {
          color: "#00BB00",
          opacity: 1.0,
          weight: 4,
          fillColor: "#00BB00",
          fillOpacity: 0.1,
          pane: pane,
        }
      }
    })
  }
}
