import * as T from '@turf/turf'
import * as L from 'leaflet'

export class Utils {
  static pointToLatLng(point: T.Feature<T.Point>): L.LatLng {
    return new L.LatLng(point.geometry.coordinates[1], point.geometry.coordinates[0])
  }

  static coordsToLatLng(coords: Array<number>): L.LatLng {
    return new L.LatLng(coords[1], coords[0])
  }

  static toPoint(latLng: L.LatLng): T.Feature<T.Point> {
    return T.point([latLng.lng, latLng.lat])
  }
}
