import * as T from '@turf/turf'
import { create } from 'domain'
import * as L from 'leaflet'
import { Utils } from './utils'

export class PinColor {
  private hex: string
  
  static Red = new PinColor("#C80000")
  static Blue = new PinColor("#3075C5")
  static Purple = new PinColor("#3C066B")
  static Pink = new PinColor("#AA0DE7")
  static Green = new PinColor("#1D5959")

  constructor(hex: string) {
    this.hex = hex
  }

  getColorString(): string {
    return this.hex
  }

  getIconUrl(isLgaOfConcern: boolean): URL {
    switch (this.hex) {
      case PinColor.Red.hex:
        if (isLgaOfConcern) {
          return new URL("resources/red-pin-warning.png", import.meta.url) 
        } else {
          return new URL("resources/red-pin.png", import.meta.url) 
        }
      case PinColor.Blue.hex:
        if (isLgaOfConcern) {
          return new URL("resources/blue-pin-warning.png", import.meta.url) 
        } else {
          return new URL("resources/blue-pin.png", import.meta.url) 
        }
      case PinColor.Purple.hex:
        if (isLgaOfConcern) {
          return new URL("resources/blue-pin-warning.png", import.meta.url) 
        } else {
          return new URL("resources/blue-pin.png", import.meta.url) 
        }
      case PinColor.Pink.hex:
        if (isLgaOfConcern) {
          return new URL("resources/blue-pin-warning.png", import.meta.url) 
        } else {
          return new URL("resources/blue-pin.png", import.meta.url) 
        }
      case PinColor.Green.hex:
        if (isLgaOfConcern) {
          return new URL("resources/blue-pin-warning.png", import.meta.url) 
        } else {
          return new URL("resources/blue-pin.png", import.meta.url) 
        }
    }
  }

  getRetinaIconUrl(isLgaOfConcern: boolean): URL {
    switch (this.hex) {
      case PinColor.Red.hex:
        if (isLgaOfConcern) {
          return new URL("resources/red-pin-warning-2x.png", import.meta.url) 
        } else {
          return new URL("resources/red-pin-2x.png", import.meta.url) 
        }
      case PinColor.Blue.hex:
        if (isLgaOfConcern) {
          return new URL("resources/blue-pin-warning-2x.png", import.meta.url) 
        } else {
          return new URL("resources/blue-pin-2x.png", import.meta.url) 
        }
      case PinColor.Purple.hex:
        if (isLgaOfConcern) {
          return new URL("resources/blue-pin-warning-2x.png", import.meta.url) 
        } else {
          return new URL("resources/blue-pin-2x.png", import.meta.url) 
        }
      case PinColor.Pink.hex:
        if (isLgaOfConcern) {
          return new URL("resources/blue-pin-warning-2x.png", import.meta.url) 
        } else {
          return new URL("resources/blue-pin-2x.png", import.meta.url) 
        }
      case PinColor.Green.hex:
        if (isLgaOfConcern) {
          return new URL("resources/blue-pin-warning-2x.png", import.meta.url) 
        } else {
          return new URL("resources/blue-pin-2x.png", import.meta.url) 
        }
    }
  }
}

export class Pin {
  private _location: T.Feature<T.Point>
  lga?: T.Feature<T.Polygon>
  color: PinColor
  radius: number

  getLGA: (p: T.Feature<T.Point>) => (T.Feature<T.Polygon> | null)

  constructor(location: T.Feature<T.Point>, color: PinColor, radius: number, getLGA: (p: T.Feature<T.Point>) => (T.Feature<T.Polygon> | null)) {
    this.getLGA = getLGA
    this.location = location
    this.lga = getLGA(this.location)
    this.color = color
    this.radius = radius
  }

  get location(): T.Feature<T.Point> {
    return this._location
  }

  set location(newLocation: T.Feature<T.Point>) {
    this.lga = this.getLGA(newLocation)
    this._location = newLocation
  }

  getFeature(shouldIncludeLGA: boolean): T.Feature<T.Polygon | T.MultiPolygon> {
    return this.createPolygon(shouldIncludeLGA)
  }

  get latLng(): L.LatLng {
    return Utils.pointToLatLng(this.location)
  }

  set latLng(latLng: L.LatLng) {
    this.location = Utils.toPoint(latLng)
  }

  createPolygon(shouldIncludeLGA: boolean): T.Feature<T.Polygon | T.MultiPolygon> {
    let steps = 200
    let polygon: T.Feature<T.Polygon | T.MultiPolygon> = T.circle(this.location, this.radius,  {
      steps: steps, 
      units: 'kilometers'
    });

    if (shouldIncludeLGA && this.lga) {
      polygon = T.union(polygon, this.lga)
    }

    return polygon
  }
}
