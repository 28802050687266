import * as T from '@turf/turf'
import { Pin } from './pin'
import { Utils } from './utils'

export class FriendZone {
  pins: Array<Pin> = []

  getFeature(shouldIncludeLGA: boolean): T.Feature<T.Polygon | T.MultiPolygon> | null {
    let pinArray = this.pins.filter(p => { 
      return p != null
    })
    if (pinArray.length <= 1) {
      return null
    }
    return pinArray
      .map(p => { 
        return p.getFeature(shouldIncludeLGA) 
      })
      .reduce((friendZoneFeature, feature) => {
        if (!friendZoneFeature) {
          return null
        }
        return T.intersect(friendZoneFeature, feature)
      })
  }
}
